<template>
  <div id="home">
    <van-nav-bar
        title="我的预约"
        fixed
    />
    <div class="login profile servers">
      <div class="v-tabss">
        <span :class="searchinfo.status==1?'current':''" @click.stop="change(1)">待服务({{number.haspaynum}})</span>
        <span :class="searchinfo.status==2?'current':''" @click.stop="change(2)">已完成({{number.finishnum}})</span>
      </div>
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getlist"
      >
        <div class="v-cell" v-for="item in orderlist" :key="item.id">
          <ul>
            <li>
              <span></span>
              <div>{{ item.order_sn }}</div>
              <div>
                <div style="display: inline-block;" v-if="searchinfo.status">
                  <em v-if="item.is_refund == 1">[退款中]</em>
                  <em v-else-if="item.is_refund == 2" style="color:#888888;">[已取消]</em>
                  <em v-else-if="item.is_deal == 0 && (item.is_refund == 0 || item.is_refund == 3)" style="color:#888888;">[待服务]</em>
                  <em v-else-if="item.is_deal == 1 && (item.is_refund == 0 || item.is_refund == 3)">[服务中]</em>
                  <em v-else-if="item.is_deal == 2 && (item.is_refund == 0 || item.is_refund == 3)">[待付尾款]</em>
                  <em v-else-if="item.is_deal == 3 && (item.is_refund == 0 || item.is_refund == 3)">[待评价]</em>
                  <em v-else-if="item.is_deal == 4 && (item.is_refund == 0 || item.is_refund == 3)" style="color:green;">[已完成]</em>
                </div>
              </div>
            </li>
            <li>{{ item.message }}</li>
            <li>
              <template v-for="items in item.checked" :key="items.id">
                <van-tag type="warning" size="medium" color="#ff0000" style="margin-right:10px;">{{items.title}}</van-tag>
              </template>
            </li>
            <li  v-if="item.is_deal == 0 && (item.is_refund == 0 || item.is_refund == 3)">
              <van-button class="gopay" :loading="loading" @click.stop="goservice(item)">立即服务</van-button>
            </li>
            <li v-else-if="item.is_deal == 1" @click.stop="showProject(item)" style="color:#ff0000;">选择服务项目</li>
            <li class="o-c" v-if="item.is_deal >= 1">
              <van-tabs v-model="active">
                <van-tab>
                  <template #title> <van-icon name="notes-o"/>技师陈述</template>
                  <div class="o-c-info">
                    <div v-if="item.is_deal == 1">
                      <van-field
                          v-model="item.masseur_remarks"
                          type="textarea"
                          placeholder="请输入您对客户服务陈述"
                          rows="3"
                          autosize
                      />
                      <van-button round block :loading="commentloading" type="info" @click.stop="gocomment(item)">提交陈述</van-button>
                    </div>
                    <div v-else>{{item.masseur_remarks}}</div>
                  </div>
                </van-tab>
                <van-tab v-if="item.custom_remarks">
                  <template #title> <van-icon name="chat-o" />服务评价</template>
                  <div>{{item.custom_remarks}}</div>
                </van-tab>
              </van-tabs>
            </li>
          </ul>
        </div>
      </van-list>
      <van-dialog v-model:show="show" :show-cancel-button="false" @confirm="getProject">
        <van-checkbox-group v-model="checked">
          <van-cell-group inset>
            <van-cell v-for="(item, index) in project" clickable :key="item.id" :title="`${item.title}`" @click="toggle(index)" >
              <template #right-icon>
                <van-checkbox shape="square" :name="item" :ref="el => checkboxRefs[index] = el" @click.stop checked-color="#ff0a00" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <van-field
            label="合计金额"
            v-model="totalprice"
            placeholder="请输入合计金额"
        />
      </van-dialog>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs,ref,onBeforeUpdate} from 'vue';
import {httpget, httpput} from "@/libs/api";
import {Dialog,Toast} from "vant";
import {httppost} from "../libs/api";

export default {
  name: 'order',
  setup() {
    const checked = ref([]);
    const checkboxRefs = ref([]);
    const toggle = (index) => {
      checkboxRefs.value[index].toggle();
    };
    const state = reactive({
      totalprice:'',
      loading:false,
      loadsing:false,
      gopayloading:false,
      commentloading:false,
      finished:false,
      show:false,
      active:1,
      orderlist:[],
      searchinfo:{
        page:0,
        status:1,
      },
      chooseorder:{},
      project:[],
      chooseproject:[],
      number: {
        needpaynum:0,
        haspaynum:0,
        finishnum:0
      },
    })
    const goservice = (item) => {
      state.loading = true;
      Dialog.confirm({
        title: '提示',
        message: '确认开始服务？',
      })
      .then(async () => {
        let res = await httpput('/api/order/service',{oid:item.id});
        if(res.code===0){
          state.loading = false;
          state.searchinfo.page = 0;
          state.orderlist = [];
          getlist();
        }else{
          state.loading = false;
          return ;
        }
      })
      .catch(() => {
        state.loading = false;
        console.log("cancel");
      });
    }
    const gocomment = async (item) => {
      if( !item.masseur_remarks ){
        Toast.fail("请输入您对客户服务陈述");
        return;
      }
      state.commentloading = true;
      let res = await httpput('/api/order/suggest',item);
      if(res.code===0){
        state.commentloading = false;
        let idx = state.orderlist.findIndex((orderone)=>{
          return orderone.id = item.id;
        })
        if(idx!==-1){
          state.orderlist[idx].is_deal = 2;
        }
        getlist();
      }else{
        Toast.fail(res.msg);
        state.commentloading = false;
        return ;
      }
    }
    const change = (value) => {
      state.searchinfo.status = value;
      state.searchinfo.page = 0;
      state.finished = false;
      state.orderlist = [];
      getlist();
    }
    const getnumber = async () =>{
      let res = await httpget('/api/order/getcount');
      if(res.code===0){
        state.number = res.data;
      }else{
        return ;
      }
    }
    const getWeek = (dateString) =>{
      var dateArray = dateString.split("-");
      let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
      return "周" + "日一二三四五六".charAt(date.getDay());
    }
    const formatDateDays = (date) => {
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
    }
    const getlist = async () =>{
      state.loading = true;
      if(!state.finished){
        state.searchinfo.page++;
      }
      let res = await httpget('/api/order/mylist',state.searchinfo);
      if(res.code===0){
        state.loading = false;
        if(state.searchinfo.page==1){
          state.orderlist = [];
        }
        if(res.data.data.length>0){
          res.data.data.forEach((task)=>{
            task.message = getyytimestr(task);
            state.orderlist.push(task);
          })
        } console.log(state.orderlist);
        if(res.data.data.length<20){
          state.finished = true;
        }
      }else{
        state.loading = false;
        state.finished = true;
        return ;
      }
    }
    const getyytimestr = (items) =>{
      let sday = '';
      if( items.datetime ){
        let hour = items.datetime.split(':');
        sday = '上午';
        if( hour[0] >= 12 ) sday = '下午';
      }
      return items.masseur_name+' '+formatDateDays(new Date(items.daytime))+"（"+getWeek(items.daytime)+"）"+(sday?sday:'')+' '+items.datetime;
    }
    const showProject = async (item) =>{
      state.loadsing = true;
      let res = await httppost('/api/order/project', {id:item.id,mid:item.masseur_id});
      if(res.code===0){
        state.show = true;
        state.project = res.data;
        let checkeds = [];
        state.project.forEach((pjt)=>{
          item.checked.forEach((cvf)=>{
            if(cvf.id == pjt.id){
              checkeds.push(pjt);
            }
          })
        });
        checked.value = checkeds;
        state.chooseorder = item;
      }else{
        state.loadsing = false;
        return ;
      }
    }
    const getProject = async() => {
      if( !state.totalprice ){
        Toast.fail('请输入合计金额');
        return false;
      }
      let checkeds = checked.value;
      state.orderlist.forEach((item)=>{
        if( item.id == state.chooseorder.id ){
          item.checked = checkeds;
        }
      });
      let res = await httppost('/api/order/chooseproject',{
        oid:state.chooseorder.id,
        project:checkeds,
        totalprice:state.totalprice
      });
      if(res.code===0){

      }else{
        return ;
      }
    }
    onMounted(() => {
      getlist();
      getnumber();
    })
    onBeforeUpdate(() => {
      checkboxRefs.value = [];
    })
    return {
      ...toRefs(state),
      change,
      showProject,
      getProject,
      getlist,
      goservice,
      getnumber,
      gocomment,
      getWeek,
      formatDateDays,
      toggle,
      getyytimestr,
      checked,
      checkboxRefs,
    };
  },
}
</script>
